<template>
    <header class="g-header">
        <h2>SEO</h2>
    </header>
    <div class="g-graph">
        <div class="g-graph__header">
            <p class="sessions">Google ranking changes</p>
            <div class="conversion-rate conversion-rate--large" v-bind:class="getComponents.seo.HeaderGraphBlock.conversionClass">
                <div class="grid-container">
                    <div class="col">
                        <p>{{ getComponents.seo.HeaderGraphBlock.seoConversion }}</p>
                    </div>
                    <div class="col">
                        <span class="arrow">
                            <i class="fa-solid fa-arrow-up-long"></i>
                        </span>
                    </div>
                </div>
            </div>
            <p class="suffix">than last month</p>
        </div>
        <apexchart ref="mainChart" height="320" type="area" :options="mainChartOptions" :series="mainSeries"></apexchart>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

// Note: as this chart is flipped (ie lower numbers at the top) we need to do some maths to fake it
// So the values get made negative, then have this variable added (see the setting for currentDaysRanking)
// The labels and axis are then formatted with the opposite algorithm to show the correct ammount
// The reversed option on https://apexcharts.com/docs/options/yaxis/ seems like it would do it, but that also reverses the fill.
const arbitraryReversalAmount = 1000000; // This just needs to be higher than any plausible value

export default {

    computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                    const GA4PropertyID = data.GA4PropertyID;
                    const propertyID = data.accountID;
                    const accessToken = data.accessToken;
                    const agencyCampaign = data.agencyCampaign;
                    const auth = data.agencyAPIkey;
                    console.log('seo header graph block init:', data);
                    console.log('seo header graph block init2:', data.agencyAPIkey);
                    if(auth && accessToken && GA4PropertyID && propertyID && agencyCampaign){
                        const response = await this.$store.dispatch('client/seoHeaderGraphBlockInit');
                        this.displayData(response);

                    }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        displayData(updatedGoogleData){

            let rankingChanges = []
            let dailyLabels = []
            let runningTotalCurrent = 0;
            const metricClean = 'googleRankingChanges';

            for (var j = 28; j > 0; j--) {
                var savedDate = new Date();
                savedDate.setDate(savedDate.getDate() - j);
                let thedate = new Date(savedDate);
                savedDate = savedDate.toISOString().slice(0, 10);
                let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                let dd = thedate.getDate()
                let currentDaysRanking = (updatedGoogleData.metrics[metricClean][savedDate] * -1) + arbitraryReversalAmount
                runningTotalCurrent = runningTotalCurrent + updatedGoogleData.metrics[metricClean][savedDate];

                rankingChanges.push(currentDaysRanking)
                dailyLabels.push(mon + ' ' + dd)

                this.$refs.mainChart.updateOptions({
                series: [{
                    name: 'Hits',
                    data: rankingChanges,
                }],
                xaxis: {
                    categories: dailyLabels,
                },
                })
            }

            this.componentHasAllData();

        },
        componentHasAllData(){

            let newVal = {
            vue: 'seoComponents',
            component: 'HeaderGraphBlock',
            val: true
            }

            this.$store.commit('client/setPageLoaded', newVal);

        },
    },
    data() {
        return {
            updatedGoogleData: '',
            seoConversion: '--',
            conversionClass: 'up',
            mainSeries: [],
            promisesMade: 0,
            errorsHappened: 0,
            mainChartOptions: {
                chart: {
                    id: 'mainChart',
                    type: 'area',
                    height: 320,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#F6C844'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                yaxis: {
                    floating: true,
                    forceNiceScale: true,
                    // min: 0,
                    labels: {
                        style: {
                            colors: ['#ffffff'],
                        },
                        offsetX: 40,
                        formatter: function(val) {
                          return ((val - arbitraryReversalAmount) * -1);
                        },
                    },
                },
                xaxis: {
                    axisBorder: {
                        color: '#4F516E',
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + ((series[seriesIndex][dataPointIndex] - arbitraryReversalAmount) * -1) + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                  text: 'Loading...'
                }
            },
        }
    },
}

</script>
