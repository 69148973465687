<template>
    <div class="g-block g-block--medium">
        <h3>
			Organic Sessions
            <div class="conversion-rate conversion-rate--large conversion-rate--right" v-bind:class="getComponents.seo.OrganicSessionsBlock.organicSessionsClass">
                <div class="grid-container">
                    <div class="col"><p>{{ formatPercentage(getComponents.seo.OrganicSessionsBlock.totalOrganicSessionsPrevious) }}</p></div>
                    <div class="col"><span class="arrow"><i class="fa-solid fa-arrow-up-long"></i></span></div>
                </div>
            </div>
		</h3>
        <span class="g-block__num">{{ getComponents.seo.OrganicSessionsBlock.totalOrganicSessions }}</span>
        <div class="g-lines">
            <apexchart ref="organicSessionsChart" height="200" type="area" :options="chartOptions" :series="chartSeries"></apexchart>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {


    computed: {
        ...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.tokens.google': {
        deep: true,
            async handler(newValue) {
                try{
                    await this.init(newValue);
                }catch(error){
                    console.log('watch error catch', error);
                }
            }
        }
    },
    async mounted(){
        try{
            await this.init(this.getSocial.tokens.google);
        }catch(error){
            console.log('mounted error catch', error);
        }
    },
    methods:{
        async init(data){
            try{
                    const GA4PropertyID = data.GA4PropertyID;
                    const propertyID = data.accountID;
                    const accessToken = data.accessToken;
                    const agencyCampaign = data.agencyCampaign;
                    const auth = data.agencyAPIkey;
                    if(auth && accessToken && GA4PropertyID && propertyID && agencyCampaign){
                        await this.$store.dispatch('client/SEOorganicSessionsBlock');
                        this.displayData();

                    }
                }catch(error){
                    console.log('init error catch', error);
                }
        },
        displayData(){

            this.$refs.organicSessionsChart.updateOptions({
                series: [{
                    name: 'Hits',
                    data: this.getComponents.seo.OrganicSessionsBlock.dailyHits,
                }],
                xaxis: {
                    categories: this.getComponents.seo.OrganicSessionsBlock.dailyLabels,
                },
            })

            this.componentHasAllData();

        },
        componentHasAllData(){

            let newVal = {
            vue: 'seoComponents',
            component: 'OrganicSessionsBlock',
            val: true
            }

            this.$store.commit('client/setPageLoaded', newVal);

        },
    },

    data() {
        return {
			totalOrganicSessions: '--',
			totalOrganicSessionsPrevious: '--',
            organicSessionsClass: 'up',
            chartSeries: [],
            promisesMade: 0,
            errorsHappened: 0,
            promises : [],
            chartOptions: {
                chart: {
                    id: 'organicSessionsChart',
                    type: 'area',
                    height: 200,
                    animations: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#F6C844'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                grid: {
                    borderColor: '#4F516E',
                },
                yaxis: {
                    forceNiceScale: true,
                    min: 0,
                    labels: {
                        style: {
                            colors: ['#ffffff'],
                        },
                    },
                },
                xaxis: {
                    axisBorder: {
                        color: '#4F516E',
                    },
                    axisTicks: {
                        show: false,
                    },
                    crosshairs: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    },
                    tooltip: {
                        enabled: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    theme: false,
                    custom: function({series, seriesIndex, dataPointIndex, w}) {
                        return '<div class="tooltip">' +
                        '<span class="tooltip__index"> ' + w.globals.categoryLabels[dataPointIndex] + '</span>' +
                        '<span class="tooltip__num"> ' + series[seriesIndex][dataPointIndex] + '</span>' +
                        '</div>'
                    },
                },
                noData: {
                  text: 'Loading...'
                }
            },
        }
    },
}

</script>
