<template>
    <div class="g-wrapper">
		<button class="g-top" @click="toTop">
			<span class="g-top__text">Top</span>
            <span class="g-top__arrow">
                <i class="fa-solid fa-arrow-up-long"></i>
            </span>
		</button>
		<HeaderGraphBlock></HeaderGraphBlock>
        <section class="g-main">
            <div class="g-blocks g-blocks--2">
                <div class="g-blocks__col">
                    <GoalCompletionsBlock></GoalCompletionsBlock>
                    <LatestRankingBlock></LatestRankingBlock>
                    <OrganicSessionsBlock></OrganicSessionsBlock>
                </div>
                <div class="g-blocks__col">
                    <ClicksImpressionsBlock></ClicksImpressionsBlock>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import HeaderGraphBlock from "../../components/seo/HeaderGraphBlock.vue"
import GoalCompletionsBlock from "../../components/seo/GoalCompletionsBlock.vue"
import LatestRankingBlock from "../../components/seo/LatestRankingBlock.vue"
import OrganicSessionsBlock from "../../components/seo/OrganicSessionsBlock.vue"
import ClicksImpressionsBlock from "../../components/seo/ClicksImpressionsBlock.vue"

export default {
    components: {
        HeaderGraphBlock,
        GoalCompletionsBlock,
        LatestRankingBlock,
        OrganicSessionsBlock,
        ClicksImpressionsBlock,
    },
    computed: {
        clientPagesLoaded: {
            get() {
				return this.$store.getters["client/getClientPagesLoaded"] || ""
            },
		},
        ...mapGetters("client", ["getClientSlug"]),
    },
    watch: {

            'clientPagesLoaded.seoComponents': {
                handler: function(newValue) {
                    console.log('comp-loaded:', newValue);
                    if (Object.values(newValue).every(val => val === true)) {
                        this.$store.commit('client/setHidePageLoader', true);
                    }else{
                        this.$store.commit('client/setHidePageLoader', false);
                    }
                //   this.$store.commit('client/setHidePageLoader', true);
                },
                deep: true
            },

    },
    methods: {
        handleScroll() {
            // Any code to be executed when the window is scrolled
            this.isUserScrolling = (window.scrollY > 0);
            if ( window.scrollY > 30 ) {
                document.querySelector(".g-wrapper").classList.add('has-scrolled');
            } else {
                document.querySelector(".g-wrapper").classList.remove('has-scrolled');
            }
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
}
</script>

<style lang="scss">
@import "./src/assets/scss/views/google.scss";
</style>
